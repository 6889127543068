/* eslint-disable react/react-in-jsx-scope -- Unaware of jsxImportSource */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { css } from '@emotion/react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Lead() {
  return (
    <Box
      sx={{
        paddingRight: { sm: 3, md: 5, lg: 8 },
        marginBottom: { xs: 9, md: 0 },
        color: (theme) => theme.palette.primary.contrastText,
      }}
    >
      <Typography
        color="inherit"
        component="h1"
        sx={{
          marginBottom: 3,
          fontSize: { xs: '40px', md: '48px', lg: '60px' },
          fontWeight: (theme) => theme.typography.fontWeightBold,
          letterSpacing: '-0.025em',
          lineHeight: '1.25',
        }}
      >
        The{' '}
        <span
          css={css`
            color: #9fe3fc;
          `}
        >
          domain watchdog
        </span>{' '}
        for brand protection
      </Typography>

      <Typography
        gutterBottom
        color="inherit"
        sx={{
          letterSpacing: '-0.025em',
          fontSize: { md: '20px' },
        }}
      >
        Probe.net provide data intelligence services to domain name experts,
        online brand protection specialists and cyber security professionals.
      </Typography>
      <Typography
        color="inherit"
        sx={{
          marginBottom: (theme) => theme.spacing(4),
          letterSpacing: '-0.025em',
          fontSize: { md: '20px' },
        }}
      >
        We are specialised in detecting domain registrations containing or
        confusingly similar to your clients’ brands.
      </Typography>

      <Box flex="1" maxWidth="240px">
        <Button
          href="#"
          variant="contained"
          fullWidth
          sx={{
            padding: { xs: '12px 12px', md: '15px 22px 12px' },
            fontSize: { md: '1.25rem' },
            backgroundColor: (theme) => theme.palette.primary.dark,
          }}
        >
          Contact us
        </Button>
      </Box>
    </Box>
  );
}
