import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

export default function Login() {
  return (
    <Box
      sx={{
        textAlign: 'center',
        // padding: (theme) => theme.spacing(3),
        padding: {
          xs: '32px 24px',
          md: '36px 24px',
        },
        backgroundColor: (theme) => theme.palette.primary.main,
        borderRadius: (theme) => theme.shape.borderRadius,
        boxShadow: '20px 20px 80px rgba(0, 0, 0, 0.51)',
        color: (theme) => theme.palette.primary.contrastText,
        maxWidth: { sm: 400 },
      }}
    >
      <Typography
        gutterBottom
        variant="h3"
        sx={{
          fontSize: { xs: '1.5rem', md: '1.875rem' },
        }}
      >
        Login to Probe.net
      </Typography>

      <Typography gutterBottom>Click here to login to your account.</Typography>

      <Button
        href="#"
        variant="contained"
        fullWidth
        sx={{
          backgroundColor: (theme) => theme.palette.primary.dark,
          padding: { xs: '12px 12px', md: '15px 22px 12px' },
          fontSize: { md: '1.25rem' },
        }}
      >
        Login
      </Button>
    </Box>
  );
}
